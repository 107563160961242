import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions as commonActions } from "../../../user-management/register/slice";
import { STORAGE_KEYS } from "../../../../common/constants";
import { SOCKET_ERROR_TYPES, SOCKET_TYPES } from "../constants";
import { actions as liveActions } from "../../../live/liveView/slice";
import { actions as commonAction } from "../../../common/slice";
import { liveStatusBulkAdd } from "../../../device/device/actions";
import { errorNotify } from "../../../../utils/notificationUtils";
import moment from "moment";

const downloadFile = async (downloadURL) => {
    try {
        // if (downloadURL && downloadURL?.length > 0) {
        //     for (const file of downloadURL) {
        const response = await fetch(downloadURL);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${moment().format("DD_MM_YYYY hh:mm")}_video.mp4`; // Assuming each object in downloadURL might have a filename property
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        //     }
        // }
    } catch (error) {
        throw new Error("Error downloading file:", error);
    }
};


const WebSocketComponent = () => {
    const dispatch = useDispatch();
    const sessionId = localStorage.getItem(STORAGE_KEYS.SESSION_ID);
    const socketRef = useRef(null);
    const pingIntervalRef = useRef(null);

    const initializeSocket = () => {
        if (sessionId) {
            const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/admin-service/ws/client/${sessionId}`);
            socket.onopen = () => {
                pingIntervalRef.current = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({ type: "IN_USER_PING", payload: { message: "Ping" } }));
                    }
                }, 10000); // Ping interval
                socketRef.current = socket;
                dispatch(commonActions.setSocketOpen(true));
                dispatch(commonActions.setWebSocket(socket));
            };

            socket.onmessage = (event) => {
                if (event.data) {
                    const data = JSON.parse(event.data);
                    switch (data.type) {
                        case SOCKET_TYPES.OUT_DEVICE_ALIVENESS:
                            dispatch(liveActions.updateConnectionStatus(data));
                            break;
                        case SOCKET_TYPES.OUT_ADD_AUTO_SEARCH_DEVICES:
                            dispatch(commonAction.setProgressiveCount());
                            dispatch(commonAction.setProgressiveData(data));
                            dispatch(liveStatusBulkAdd(data));
                            break;
                        case SOCKET_TYPES.OUT_LIVE_STREAM_SUCCESS:
                            dispatch(liveActions.updateLiveViewUrl({ ...data.payload, status: "SUCCESS" }));
                            break;
                        case SOCKET_TYPES.OUT_LIVE_STREAM_FAILED:
                            // console.log("here failure", data?.payload);
                            dispatch(liveActions.updateLiveViewUrl({ ...data.payload, status: "FAILURE" }));
                            break;
                        case SOCKET_TYPES.OUT_CORE_ERROR:
                            if (data?.payload?.errorType === SOCKET_ERROR_TYPES.STREAMING) {
                                dispatch(liveActions.setStreamError(data?.payload));
                            } else {
                                dispatch(errorNotify({ title: "Error", message: data?.payload?.errorString }));
                            }
                            break;
                        case SOCKET_TYPES.OUT_FILE_DOWNLOADED:
                            downloadFile(data?.payload?.downloadUrl);
                            // dispatch(playBackActions.setDownloadUrl(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_LIVE_STREAM_RETRY_SUCCESS:
                            dispatch(liveActions.updateLiveViewUrl({ ...data.payload, status: "RETRY_SUCCESS" }));
                            break;
                        case SOCKET_TYPES.OUT_LIVE_STREAM_RETRY_FAILED:
                            dispatch(liveActions.updateLiveViewUrl({ ...data.payload, status: "RETRY_FAILED" }));
                            break;
                        case SOCKET_TYPES.OUT_SYSTEM_INFO:
                            dispatch(commonAction.setCpuAndMemory(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_BACKUP_STATUS:
                            dispatch(commonAction.setBackupStatus(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_RESTORE_STATUS:
                            dispatch(commonAction.setRestoreStatus(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_SYSTEM_RECORDING_STOPPED:
                            dispatch(commonAction.setWarningMessages(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_SYSTEM_RECORDING_RESUMED:
                            dispatch(commonAction.setSuccessMessages(data?.payload));
                            break;
                        case SOCKET_TYPES.OUT_STOP_LIVE_STREAM:
                            dispatch(liveActions.updateLiveViewUrl({ ...data.payload, status: "GROUP_CHANGED" }));
                            break;
                        default:
                            break;
                    }
                }
            };

            socket.onclose = (event) => {
                clearInterval(pingIntervalRef.current);
                dispatch(commonActions.setSocketOpen(false));
                dispatch(commonActions.setPingInterval(0));
                dispatch(commonActions.setWebSocket(null));
                socketRef.current = null;
                if (!event.wasClean) {
                    setTimeout(initializeSocket, 3000); // Reconnect after 3 seconds
                }
            };

            socket.onerror = () => {
                socket.close();
            };
        }
    };

    useEffect(() => {
        if (!socketRef.current) {
            initializeSocket();
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            clearInterval(pingIntervalRef.current);
        };
    }, [dispatch, sessionId]);

    return null;
};

export default WebSocketComponent;
